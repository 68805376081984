.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.nav-link {
  opacity: 0.85;
  transition-duration: 0.15s;
}

.nav-link:hover {
  opacity: 0.6;
}

.nav-link:active {
  opacity: 0.4;
}

.nav-link.purple {
  opacity: 1;
}

.nav-link.purple:hover {
  opacity: 0.8 !important;
}

.nav-link.purple:active {
  opacity: 0.6 !important;
}

#nav-container-home {
  justify-content: space-around;
  justify-content: space-evenly;
}

#nav-container {
  justify-content: space-around;
  justify-content: space-evenly;
}

.clickable {
  cursor: pointer;
  transition-duration: 0.15s;
}

.clickable:hover {
  opacity: 0.75;
}

.clickable:active {
  opacity: 0.55;
}

@media only screen and (max-width: 700px) {
  .content {
    width: 95vw !important;
  }
  .header {
    width: 75vw !important;
    min-height: auto !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 400px) {
  .nav-link {
    min-height: 45px !important;
  }
}
